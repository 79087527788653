import React from "react";
import PostHeader from "./PostHeader";
import EntryContent from "./EntryContent";
import StaticEntryContent from "./StaticEntryContent";
import StoriesGrid from "components/StoriesGrid";
import Contact from "components/Contact";
import Footer from "components/shared/Footer";
import Logos from "components/Logos";
import Nav from "components/shared/Nav";
import Meta from "components/shared/Meta";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { graphql } from "gatsby";

const Post = ({
  data: {
    wpPost: { title, content, id, featuredImage, additionalInformation},
  },
}) => {

  console.log({
    title,
    additionalInformation,
    id,
  })  

  const image = getImage(featuredImage?.node?.localFile);

  return (
      <>
         <Meta url="/post" />
         <Nav activeSection={"Blog"} color={"dark"}/>
         <PostHeader title={title} extraInfo={additionalInformation}></PostHeader>
         {additionalInformation.autocompile
          ? <EntryContent title={title} image={image} content={content}></EntryContent>
          : <StaticEntryContent title={title} image={image} id={id}></StaticEntryContent>
         }
         <Contact></Contact>
         <Logos></Logos>
         <Footer />
      </>
  )
}

export default Post

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
        id
        title
        content
        additionalInformation{
          publishingDate
          author
          summary
          autocompile
        }
        featuredImage {
            node {
            sourceUrl
            localFile {
                publicURL
                childImageSharp {
                gatsbyImageData(
                    width: 1100
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                }
            }
            }
        }
    }
  }
`