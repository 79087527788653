import React from "react";
import { container, imageContainer, entryContent } from "./index.module.less";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "components/shared/Layout";

export default ({ children, title, image ,content }) => (
    <Layout>
      <div className={[container]}>
        {/* <div className={[imageContainer]}></div> */}

        <div className={[entryContent]}>
          <div>
            {image ? (
              <GatsbyImage image={image} alt={title} loading="eager" />
              ) : null
            }
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </Layout>
);
