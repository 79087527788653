import React from "react";
import { container, imageContainer, entryContent } from "./index.module.less";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "components/shared/Layout";

// #region Static Content
const static1 = () => {
  return (
    <div>
      <p>
        集思社公众号现已正式上线啦！集思社是在中英健康老龄化项目下设立的一个社区，由英国国家老龄化创新中心运营的社区。集思社的成员将包括老年人、他们的照顾者、服务提供者以及关注老龄化问题和老年护理行业的人士。我们将在集思社公众号和官网上分享与健康老龄化相关的实用知识和创新趋势。
        集思社公众号是集思社成员们更好地参与活动，了解社区最新动态的一个重要途径，也是获取和老龄健康，老年生活相关的实用知识及创新趋势的内容平台。
      </p>

      <p></p>

      <p>
        <br />
        首先，集思社成员可以在集思社公众号上看到对于集思社及其所属的中英健康老龄化项目的介绍，还不熟悉“集思社”是什么的朋友们，这篇文章会帮助您大致地了解一下集思社。公众号上还能看到与小程序使用相关的教学文章，例如”如何成为集思社问卷的成员“，“如何参与小程序中的活动”，这些文章能够在您初期熟悉小程序的过程中更好地帮助您了解它的功能和用法。
      </p>

      <p></p>

      <p></p>

      <p>
        我们也会在公众号上发布近期在小程序上可参与的精彩活动集合，以及过往活动回顾，方便您看看最近有什么问卷、日记或线上/线下活动是您感兴趣，想要参加的。新来的朋友们也可以看看活动回顾，了解我们之前举办过的活动，以及成员参与活动的心得分享。
        <br />
      </p>

      <p></p>

      <p>
        公众号的另一重要部分是资讯分享，包括与老年人相关的科普知识，生活小常识，休闲娱乐资讯(如最新网络流行词解析），同龄老年人日常生活分享等。这一部分内容会定期在公众号上更新，我们会将发布的内容集中于与老年人息息相关或能让老年人及利益相关者（如老年人的照顾者，养老产业相关人员等）感兴趣的主题。无论是了解健康、养生方面的知识，还是科普时下流行的事物，获取最新的信息，我们的出发点都是让老年朋友们能觉得这些文章读起来还挺有意思或对自己有所帮助。
      </p>

      <p></p>

      <p></p>

      <p>
        以上是关于集思社公众号的简单介绍，欢迎大家扫描下面的二维码来订阅我们的集思社公众号。如果您对于我们的公众号以及发布的文章主题和内容有任何建议，欢迎在本文评论区给我们留言。在每一位集思社成员的支持和帮助下，希望集思社公众号能越办越好。
      </p>

      <p></p>

      <p></p>

      <p>欢迎扫描以下二维码关注集思社公众号</p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "fit-content",
        }}
      >
        <img
          loading="lazy"
          width="430"
          height="430"
          src="https://aic-wp.tech/nica-uk
    /wp-content/uploads/sites/2/2022/11/image-3.png"
          alt="QR-Code"
          margin="0 auto"
        />
      </div>
    </div>
  );
};

// #endregion

const staticContent = {
  "cG9zdDoxMjA=": static1(),
};

export default ({ children, title, image, id }) => {
  return (
    <Layout>
      <div className={[container]}>
        <div className={[entryContent]}>
          <div>
            {image ? (
              <GatsbyImage image={image} alt={title} loading="eager" />
            ) : null}
          </div>
          {staticContent[id]}
        </div>
      </div>
    </Layout>
  );
};
