import React from "react";
import { container, content, smallText, dateAndAuthor } from "./index.module.less";
import Layout from "components/shared/Layout";

export default ({ children, title, extraInfo }) => (
  <header className={container}>
    <Layout>
      <div className={[content]}>
        {/* <h1>
          China 
            <span style={{color:"#fbd34c"}}>Healthy Ageing</span> Project
        </h1> */}
        {/* Title here */}
        <p className={[smallText]}><b style={{fontWeight:400}}>{extraInfo.publishingDate}</b> | {extraInfo.author}</p>
        <h1>
          {title}
        </h1>
        {/* Tiny Summary */}
        <p>{extraInfo.summary}</p>
        {/* <p className={[smallText]}>
        这些活动是通过英国国家科研与创新署（UKRI），国际合作基金（FIC）提供资金支持，由英国创新署（UKRI的一部分）实施。
        </p> */}
      </div>
    </Layout>
  </header>
);
